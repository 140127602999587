import React, { Component } from 'react'
import { HashRouter, Route, Routes } from 'react-router-dom'
import { CFooter } from "@coreui/react";
import './scss/style.scss'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

class App extends Component {
  render() {
    return (
      <HashRouter>
        <React.Suspense fallback={loading}>
          <Routes>
            <Route path="/*" name="Home" element={<DefaultLayout />} />
          </Routes>
        </React.Suspense>
        <CFooter className='bg-table-thead'>
          <div>
            <span>&copy; 2021-2023 kgns - relre</span>
          </div>
        </CFooter>
      </HashRouter>
    )
  }
}

export default App;
